import React from 'react';

const Privacy = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <>
      <div
        onClick={onClose}
        className="absolute inset-0 w-full h-screen flex justify-center items-center text-center text-transparent bg-clip-text bg-gradient-to-r from-pink-600 via-pink-600 to-purple-400 privacy font-mono p-4 overflow-auto"
      >
        <div
          className="items-center justify-center text-left md:w-[50%] h-full p-4"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h1 className="text-center font-bold text-4xl text-transparent bg-clip-text bg-gradient-to-r from-pink-600 via-pink-600 to-purple-400 title">
            Privacy Policy
          </h1>
          <div className="text-lg text-transparent bg-clip-text bg-gradient-to-r from-pink-600 via-pink-600 to-purple-400">
            <h1 className="text-2xl font-bold py-4">Who we are</h1>
            <p>Our website address is: https://statselector.com.</p>
            <h1 className="text-2xl font-bold py-4">
              Who we share your data with
            </h1>
            <p className="text-justify">
              This website https://statselector.com no longer uses third-party
              cookies. There are no Analytics or Ads used on this website.
            </p>
          </div>
          <div className="md:w-[25%] mx-auto">
            <button onClick={onClose} className="btn cursor-pointer ">
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
